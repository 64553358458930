@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-BoldItalic.eot');
	src: url('Montserrat-BoldItalic.eot?#iefix') format('embedded-opentype'),
		url('Montserrat-BoldItalic.woff2') format('woff2'),
		url('Montserrat-BoldItalic.woff') format('woff'),
		url('Montserrat-BoldItalic.ttf') format('truetype'),
		url('Montserrat-BoldItalic.svg#Montserrat-BoldItalic') format('svg');
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-Bold.eot');
	src: url('Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
		url('Montserrat-Bold.woff2') format('woff2'), url('Montserrat-Bold.woff') format('woff'),
		url('Montserrat-Bold.ttf') format('truetype'),
		url('Montserrat-Bold.svg#Montserrat-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-Medium.eot');
	src: url('Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
		url('Montserrat-Medium.woff2') format('woff2'), url('Montserrat-Medium.woff') format('woff'),
		url('Montserrat-Medium.ttf') format('truetype'),
		url('Montserrat-Medium.svg#Montserrat-Medium') format('svg');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-ExtraBold.eot');
	src: url('Montserrat-ExtraBold.eot?#iefix') format('embedded-opentype'),
		url('Montserrat-ExtraBold.woff2') format('woff2'),
		url('Montserrat-ExtraBold.woff') format('woff'),
		url('Montserrat-ExtraBold.ttf') format('truetype'),
		url('Montserrat-ExtraBold.svg#Montserrat-ExtraBold') format('svg');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-MediumItalic.eot');
	src: url('Montserrat-MediumItalic.eot?#iefix') format('embedded-opentype'),
		url('Montserrat-MediumItalic.woff2') format('woff2'),
		url('Montserrat-MediumItalic.woff') format('woff'),
		url('Montserrat-MediumItalic.ttf') format('truetype'),
		url('Montserrat-MediumItalic.svg#Montserrat-MediumItalic') format('svg');
	font-weight: 500;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-Regular.eot');
	src: url('Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
		url('Montserrat-Regular.woff2') format('woff2'),
		url('Montserrat-Regular.woff') format('woff'),
		url('Montserrat-Regular.ttf') format('truetype'),
		url('Montserrat-Regular.svg#Montserrat-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-ThinItalic.eot');
	src: url('Montserrat-ThinItalic.eot?#iefix') format('embedded-opentype'),
		url('Montserrat-ThinItalic.woff2') format('woff2'),
		url('Montserrat-ThinItalic.woff') format('woff'),
		url('Montserrat-ThinItalic.ttf') format('truetype'),
		url('Montserrat-ThinItalic.svg#Montserrat-ThinItalic') format('svg');
	font-weight: 100;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-Thin.eot');
	src: url('Montserrat-Thin.eot?#iefix') format('embedded-opentype'),
		url('Montserrat-Thin.woff2') format('woff2'), url('Montserrat-Thin.woff') format('woff'),
		url('Montserrat-Thin.ttf') format('truetype'),
		url('Montserrat-Thin.svg#Montserrat-Thin') format('svg');
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}
