@use './assets/fonts/kollektif/stylesheet.scss' as *;
@use './assets/fonts/montserrat/stylesheet.scss' as *;
@use './assets/fonts/norwester/stylesheet.scss' as *;
@use './partials/index' as *;

@tailwind base;
@tailwind components;
@tailwind utilities;

.test {
  height: 2400px;
}

@for $i from 0 through 100 {
  .animation-delay-#{$i} {
    animation-delay: $i * 0.2s;
  }
}

@layer base {
  section {
    @apply p-4 scroll-mt-20;

    &:nth-child(even) {
      @apply bg-light-blue;
    }

    @media screen and (min-width: 768px) {
      padding: 3rem 2rem;
    }
  }
}

@layer components {
  .transition-custom-all {
    transition: all 0.5s ease-in-out;
  }

  .Hex {
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  }

  .modal {
    &--hide {
      @apply opacity-0 animate-rotate-modal-reverse;
    }

    &--view {
      @apply opacity-100 animate-rotate-modal;
    }
  }

  .ji-divider {
    @apply bg-blue h-4 w-4 mx-auto rotate-45;

    &::before {
      @include divider-sides(before);
    }

    &::after {
      @include divider-sides(after);
    }
  }

  .cta {
    @apply inline-block text-green py-2 px-4 border border-green rounded-full hover:bg-green hover:text-white md:ml-8;
  }
}
