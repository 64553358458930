@font-face {
	font-family: 'Kollektif';
	src: url('Kollektif.eot');
	src: url('Kollektif.eot?#iefix') format('embedded-opentype'),
		url('Kollektif.woff2') format('woff2'), url('Kollektif.woff') format('woff'),
		url('Kollektif.ttf') format('truetype'), url('Kollektif.svg#Kollektif') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Kollektif';
	src: url('Kollektif-BoldItalic.eot');
	src: url('Kollektif-BoldItalic.eot?#iefix') format('embedded-opentype'),
		url('Kollektif-BoldItalic.woff2') format('woff2'),
		url('Kollektif-BoldItalic.woff') format('woff'),
		url('Kollektif-BoldItalic.ttf') format('truetype'),
		url('Kollektif-BoldItalic.svg#Kollektif-BoldItalic') format('svg');
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Kollektif';
	src: url('Kollektif-Italic.eot');
	src: url('Kollektif-Italic.eot?#iefix') format('embedded-opentype'),
		url('Kollektif-Italic.woff2') format('woff2'), url('Kollektif-Italic.woff') format('woff'),
		url('Kollektif-Italic.ttf') format('truetype'),
		url('Kollektif-Italic.svg#Kollektif-Italic') format('svg');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Kollektif';
	src: url('Kollektif-Bold.eot');
	src: url('Kollektif-Bold.eot?#iefix') format('embedded-opentype'),
		url('Kollektif-Bold.woff2') format('woff2'), url('Kollektif-Bold.woff') format('woff'),
		url('Kollektif-Bold.ttf') format('truetype'),
		url('Kollektif-Bold.svg#Kollektif-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}
