@font-face {
	font-family: 'Norwester';
	src: url('Norwester-Regular.eot');
	src: url('Norwester-Regular.eot?#iefix') format('embedded-opentype'),
		url('Norwester-Regular.woff2') format('woff2'), url('Norwester-Regular.woff') format('woff'),
		url('Norwester-Regular.ttf') format('truetype'),
		url('Norwester-Regular.svg#Norwester-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
